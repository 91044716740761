import PropTypes from 'prop-types';
import { pick } from 'ramda';

import { Types } from 'utils/Presenter';
import { makePresenter } from 'utils/PropTypesPresenter';

const shape = {
  organization: Types.object,
  role: Types.string,
  grades: Types.array,
  subjects: Types.array,
};

export type MembershipFormType = PropTypes.InferProps<typeof shape>;

export default makePresenter(shape, {
  isValid(membership) {
    return !!this.organization(membership);
  },

  defaultAttributes(membership = {}) {
    return {
      organization: this.organization(membership) || {},
      role: this.role(membership) || '',
      grades: this.grades(membership) || [],
      subjects: this.subjects(membership) || [],
    };
  },

  attributesToSubmit(attributes) {
    const tags = [...attributes.grades, ...attributes.subjects];
    const protectedAttrs = pick(['role'], attributes);

    return {
      membership: {
        ...protectedAttrs,
        organization_id: this.organization(attributes).id,
        taggings_attributes: tags.map(tag => ({ tag_id: tag.id })),
      },
    };
  },

  attributesToSubmitWithTagIds(attributes) {
    const tags = [...attributes.grades, ...attributes.subjects];
    const protectedAttrs = pick(['role'], attributes);

    return {
      membership: {
        ...protectedAttrs,
        organization_id: this.organization(attributes).id,
        taggings_attributes: tags.map(tagId => ({ tag_id: tagId })),
      },
    };
  },
});
